<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <h3>Detalhes da atividade</h3>
    <div>
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="activity.name" disabled>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Modalidade</label>
          <InputSelect collection="modalities" object="modality" fieldKey="id" fieldText="name" v-bind:current="activity.modality_id" @select="(sel) => activity.modality_id = sel" rows="200" :readonly="true"/>
        </div>
        <div class="form-group col-md-4">
          <label>Local</label>
          <InputSelect collection="locations" object="location" fieldKey="id" fieldText="name" v-bind:current="activity.location_id" @select="(sel) => activity.location_id = sel" rows="200" :readonly="true"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          Dias: {{ this.weekDaysList }}
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.starttime" disabled>
        </div>
        <div class="form-group col-md-2">
          <input type="time" class="form-control" id="name" placeholder="Nome" v-model="activity.endtime" disabled>
        </div>
      </div>

      <div style="float: right;">
        <router-link class="btn btn-default" style="margin-left:10px;" to="/activities">Fechar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { sAlert } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';
import InputSelect from '../commons/InputSelect.vue';

export default {
  components: {
    InputSelect,
  },
  data() {
    return {
			presenceList: [],
      checkedDays: [],
			strFields: '',
      activity: {},
      activityDate: moment().format('YYYY-MM-DD'),
			page: 1,
			size: 100,
			pages: 0,
			sort: 'name',
			param: '',
      mapStatus: {
        uninformed: '',
        present: 'Presente',
        absent: 'Ausente',
        justified: 'Justificado',
      }
		};
  },
  watch: {
    activityDate() {
      this.fetchGetList();
    }
  },
  computed: {
		filters() {
			if (this.param) return { name: this.param };

			return {};
		},
    weekDaysList() {
      let days = '';
      const nameDays = ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'];
      for (const day of this.checkedDays) {
        if (days == '') {
          days = nameDays[day];
        } else {
          days += ', ' + nameDays[day];
        }
      }
      return days;
    },
	},
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
    async fetchGetData() {
			ApiAxios.get(`activities/${this.$route.params.id}`)
        .then((res) => {
          this.activity = res.data.activity;
          if (this.activity.days) this.checkedDays = this.activity.days;
          this.$root.loadingVisible = false;
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403) {
            this.$router.back();
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
		},
		async fetchGetList() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`activities/${this.$route.params.id}/presenceList`, { activitydate: this.activityDate })
			.then((res) => {
        this.presenceList = res.data.presenceList;
				// this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		search() {
			if(this.page === 1) { return this.fetchGetList(); }
			this.page = 1;
		},
    setStatus(id, status) {
			let that = this;
			this.$root.loadingVisible = true;
      ApiAxios.post(`activityAssociatePresences`, { activityAssociatePresence: {
          activity_associate_id : id,
          status,
          activitydate: this.activityDate
        }})
        .then(() => {
          this.$root.loadingVisible = false;
          that.fetchGetList();
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response && e.response.status == 403) {
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });  
				
			
		},
	},
	created () {
    this.fetchGetData();
		this.fetchGetList();
	},
}
</script>  
